@font-face {
    font-family: "Moret Bold";
    src: url(../../public/fonts/Moret-Trial/Trial/Moret-Bold.otf); 
}
@font-face {
    font-family: "Work Sans Medium";
    src: url(../../public/fonts/Work_Sans/static/WorkSans-Medium.ttf); 
}

:root{
    /* Font */
    --header-font: "Moret Bold", serif;
    --body-font: "Work Sans Medium", sans-serif;

    /* Size */
    --h1-font-size: 4.6rem;
    --h2-font-size: 1.81rem;
    --body-font-size: 1.13rem;
    --h1-font-size-reduced: calc(var(--h1-font-size) * .75);

    --h1-line-height: 104%;
  
    --zyzics-color: #FFDF00;
    --zyzics-blue: #0020FF;
    --zyzics-grey: #F3F5FC; 
    --zyzics-grey2: #dde;
    --zyzics-deep-blue: #17226d;

    /* Content padding */
    --content-padding: 0 5rem;
    --content-edge: 0 2rem;

    --border-radius: 2px;




    --section: 4.9rem;
    --content-inner: 0 10rem;
  
    --header1-size: 5.4rem; 
    --header1-lineheight: 7rem; 
    --paragraph-size: 1.2rem;
    --header2-size: 2.55rem;
}

/* === RESETS ==== */
*{
    margin: 0; 
    padding:0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none; 
    border: none;
    font-family: var(--body-font) !important;
    /* transition: all .2s linear; */
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}
*::selection{
    background: var(--zyzics-blue);
    color: #fff;
}
html{
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;
    font-size: 100%;  /* change for different device sizes */
}
img{
    max-width: 100%;
    height: auto;
}
h1, h2, h3{
    font-family: var(--header-font) !important;
}
h1{    
    font-weight: 600;
    font-size: 65px;
    line-height: 66px;
    text-transform: capitalize;
}
h2{
    font-style: italic;
    font-weight: 700; 
    font-size: var(--h2-font-size);
    line-height: 39px;
}
p{
    font-family: var(--body-font) !important;
    font-weight: 500;
    font-size: var(--body-font-size);
    line-height: 32px;
}
/*** SCREEN ***/
#screen{    
    background-color: black;
    height: 100vh;
    /* background-image: radial-gradient(rgba(255, 255, 255, 0.2) 3%, transparent 65%); */
  /* background-size: 100px 200px 50px; 
  background-repeat: no-repeat; 
  background-position: left; */
}
/*** BANNER ***/
#banner{
    display: flex;
    height: 80vh;
    /* background-color: blue; */
}
#banner div{
    color: white;
    width: 40%;
    align-content: center !important;
    margin: 0 !important;
}
.zyswiper{
    /* background-color: yellow; */
    width: 100% !important;
    align-content: center !important;
    padding: var(--content-edge);
}

.image-div{
    /* width: auto !important; */
}

#banner h2{
    text-align: right;
    padding-left: 1.5rem;
    margin-top: 2.5rem;
}
#banner button{
    background-color: transparent;
    color: white;
    border: 1px solid var(--zyzics-color);
    padding: .8rem !important;
    margin-left: auto;
    margin-top: .5rem !important;
    font-family: var(--header-font) !important;
}
#banner button:hover{
    background-color: var(--zyzics-color);
    color: black;
}
/* #banner :nth-child(2){
THE IMAGE PART
    background-color: green;
} */

/*** HEADER ***/
#header{
    display: flex; 
    width: 100%;
    justify-content: space-between !important;
    padding: var(--content-edge);
    padding-block: 2rem;
}
#header-left{
    /* background-color: white !important; */
    color: white;
    display: flex;
    align-items: center; 
}
#header-right{
    /* background-color: green;  */
    display: flex;
    color: #fff;
}
#header-right ul{
    list-style: none; 
    display: flex;
    align-items: center; 
    gap: 1.3rem; 
    padding-right: 1rem;
}
#header-right li a{
    color: white;
    font-family: var(--body-font);
    font-size: var(--body-font-size);
}
#header-right li:nth-child(5), #header-right li:nth-child(6){
    color: var(--zyzics-deep-blue);
}
/* SPAN */
/* span{
    color: var(--zyzics-color);
    font-family: var(--header-font);
    font-style: italic;
} */

/* BUTTON */
button{
    background-color: var(--zyzics-color);
    color: black;
    font-family: var(--header-font) !important;
    font-size: var(--body-font-size);
    padding: 1rem !important; 
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
    align-items: center;
    align-content: center !important;
    text-transform: uppercase; 
    border: 2px solid var(--zyzics-color);
    /* height: 4rem; */
}
button:hover{
    background-color: transparent;
    color: white;
    border: 2px solid var(--zyzics-color);
}

/* #header button{
    margin-block: 2rem;
} */

/* GRIDS */
.c1, .c2, .c3, .c4, .c5, .c6, .c1_2, .c2_1, .c3_1, .c3_2, .c5_2{
    display: grid;
  }
  .c1{ grid-template-columns: repeat(1, 1fr); }
  .c2{ grid-template-columns: repeat(2, 1fr); }
  .c3{ grid-template-columns: repeat(3, 1fr); }
  .c4{ grid-template-columns: repeat(4, 1fr); }
  .c5{ grid-template-columns: repeat(5, 1fr); }
  .c6{ grid-template-columns: repeat(6, 1fr); }
  /* diff dimensions */
  .c1_2{ grid-template-columns: 1fr 2fr; column-gap: .5rem; }
  .c2_1{ grid-template-columns: 2fr 1fr; column-gap: .5rem; }
  .c3_1{ grid-template-columns: 3fr 1fr; column-gap: .5rem; }
  .c3_2{ grid-template-columns: 3fr 2fr; column-gap: .5rem; }
  .c5_2{ grid-template-columns: 67% 33%; }

/* NOTES */
/* margin-left: auto; //pushes an element or button to the right. */